export enum BannerPosition {
  HOME = 'home',
  PLANS = 'plans',
  DEALS = 'deals',
}

export enum BannerType {
  IMAGE = 'image',
  VIDEO = 'video',
  TIMER = 'timer'
}

export enum BannerImageRatio{
  DEFAULT = 192 / 79,
  PLANS_RATIO = 192 / 19
}

export enum BannerMobileImageRatio{
  DEFAULT = 75 / 62,
  PLANS_RATIO = 192 / 19
}
export interface Banner {
  id: number
  name: string
  title: string
  subtitle: string
  type: BannerType
  video: string
  mobile_video: string
  video_link: string
  image: string
  image_link: string
  mobile_image: string
  mobile_image_link: string
  link: string
  sort: number
  cta: string
  position: BannerPosition
  created_at: string
  updated_at: string
  expired_at: string
}

export type PartialBanner = Partial<Banner>;
